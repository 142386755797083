import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.product_menu',
      search: 'product-menu.search_keyword',
      filter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      // displayMode: 'table',
      // displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      // card: {
      //   title: row => row.name,
      //   subtitle: row => row.product_quantity,
      //   avatarPhoto: row => row.avatar,
      //   otherValues: row => {
      //     return [
      //       vm.$t(`data.status.${row.status}`)
      //     ]
      //   },
      // },
      // table: {
      //   data: <TableDataType>[
      //     {
      //       key: 'name',
      //       label: 'data.name',
      //       type: 'text',
      //     },
      //     {
      //       'key': 'product_quantity',
      //       label: 'product-menu.data.product_quantity',
      //       type: 'text',
      //     },
      //     {
      //       key: 'status',
      //       label: 'data.status',
      //       type: 'switch',
      //       isNumberType: true,
      //     },
      //     {
      //       key: 'created_at',
      //       label: 'data.created_at',
      //       type: 'time',
      //     },
      //   ],
      // },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      metaAction: {
        create: {
          label: '新增分類',
          linkTarget: '_self',
          type: 'route',
          route: () => ({ name: 'product-menu-create' }),
          component: () => import('@/components/list/listMetaAction/roundedBtn.vue'),
  },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'product-menu-update', params: { target: row.id } }),
          linkTarget: '_self',
          color:'#12178E',
          buttonProperties: row => ({
            style: {
              borderRadius: '999px',
            }
          }),
        },
        // delete: {
        //   removeTarget: row => row.name,
        // },
      },
    }

  }
}

export default new listConfig()
