<template>
    <v-container class="my-4 px-4">
        <!-- 額外列表操作 -->
        <div class="d-flex px-2 justify-end">
            <listMetaAction
                :list-key="listKey"
            ></listMetaAction>
        </div>
        <div class="px-2">
            <listSearchBar
                class="my-4"
                :listKey="listKey"
            ></listSearchBar>
        </div>

        <div class="d-flex justify-space-between px-4 pb-4">
            <!-- 全選/取消全選 -->
            <div class="d-flex align-center pl-4" >
                <v-checkbox
                    v-if="createSelectAllButton"
                    @click="toggleSelectAll"
                    class="absolute"
                    v-model="hasSelectedAll"
                >
                </v-checkbox>
                <div class="pl-10">全選</div>
            </div>


            <!-- 批次操作 -->
            <batch-delete
                :key="`batch-delete`"
                :list-key="listKey"
                :config="`delete`"
            ></batch-delete>
        </div>

        <v-divider/>

        <!-- 條列模式 -->
        <listRowMode
            class="my-4"
            :listKey="listKey"
        ></listRowMode>
    </v-container>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productMenuListConfig'

export default {
    mixins: [listMixin],
    components: {
        listSearchBar: () => import('@/components/list/listSearchBar.vue'),
        listMetaAction: () => import('@/components/list/listMetaAction.vue'),
        listRowMode: () => import('@/components/list/listRowMode'),
        batchDelete: () => import('@/components/list/listToolbar/batchDelete.vue'),

    },
    data: () => ({
        listKey: 'product-menu-list',
        meta: {},
    }),
    computed: {
        providerId() {
            return this.$store.getters['member/providerId']
        },
        createSelectAllButton() {
            if(!this.hasData) return false
            if(!this.hasSelectFeature) return false
            if(this.isPopupMode) return false // module selector模式不使用全選toggle功能
            return true
        },
        hasSelectFeature() {
            return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
        },
        usedListEmpty() {
            return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
        },
        isPopupMode() {
            return this.$store.getters[`list/${this.listKey}/isPopupMode`]
        },
        selectorSingleMode() {
            return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
        },
        hasData() {
            return this.$store.getters[`list/${this.listKey}/hasData`]
        },
        targetKey() {
            return this.$store.getters[`list/${this.listKey}/targetKey`]
        },
        listActions() {
            return this.$store.getters[`list/${this.listKey}/actions`]
        },
        hasSelectedAll() {
            return this.$store.getters[`list/${this.listKey}/hasSelectedAll`]
        },
        listConfig() {
            return this.$store.getters[`list/${this.listKey}/config`]
        },
        listData() {
            return this.$store.getters[`list/${this.listKey}/data`]
        },
        listHasSelected() {
            return this.$store.getters[`list/${this.listKey}/hasSelected`]
        },
    },
    methods: {
        async beforeIndex() {
            await Promise.all([])
        },
        async indexApi(params) {
            return await this.$api.collection.productMenuApi.index(this.providerId, params)
        },
        async patchApi(value, row, config) {
            const target = row.id
            const column = config.key
            return await this.$api.collection.productMenuApi.patch(this.providerId, target, column, value)
        },
        async deleteApi(target) {
            return await this.$api.collection.productMenuApi.delete(this.providerId, target)
        },
        async batchApi(targets, column, value) {
            return await this.$api.collection.productMenuApi.batch(this.providerId, targets, column, value)
        },
        async batchDeleteApi(targets) {
            return await this.$api.collection.productMenuApi.batchDelete(this.providerId, targets)
        },
        getListConfig() {
            return listConfig
        },
        toggleSelectAll() {
            // 已全選, 取消全選
            if(this.hasSelectedAll) {
                this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
                return
            }


            // 未全選, 執行全選
            const selected = this.listData.map(row => {
                if(typeof this.listConfig.selectedData === 'function') {
                    return this.listConfig.selectedData(row)
                }

                return { [this.targetKey]: row[this.targetKey] }
            })
            this.$store.dispatch(`list/${this.listKey}/setSelected`, selected)
        },

    },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
